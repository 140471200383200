import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Food } from '../food';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-food-detail',
  templateUrl: './food-detail.component.html',
  styleUrls: ['./food-detail.component.css']
})
export class FoodDetailComponent implements OnInit {

  food: Food;

  id?: number;
  initialized: boolean;
  debugInfo! : string;

  foodCategories!: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
) { 
  this.food = new Food();
  this.initialized = false;
}

  ngOnInit(): void {
    this.getFood();
    this.foodCategories = this.apiService.getFoodCategories();

  }

  getFood(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.apiService.readFood(this.id)
      .subscribe(food => {
        this.food = food;
        this.initialized = true;
        console.log("Found:" + food.label)
        //this.debugInfo = this.route.snapshot.paramMap.get('id')!;
      });
  }

  remove(): void {
    this.apiService.deleteFood(this.food.id)
      .subscribe(food => {
        console.log("Deleted:" + this.food.label)
      })
  }

  undelete(): void {
    this.food.valid = 1;
    this.update();
  }

  update(): void {
    this.apiService.updateFood(this.food)
      .subscribe(food => {
        console.log("Updated:" + this.food.label)
      })

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Food } from './food';
import { environment } from './../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  readFoods(): Observable<Food[]>{
    return this.httpClient.get<Food[]>(`${environment.backend}/api/read.php`);
  }
  
  readFood(id: number): Observable<Food>{
    return this.httpClient.get<Food>(`${environment.backend}/api/read.php?id=${id}`);
  }

  createFood(food: Food): Observable<Food>{
    return this.httpClient.post<Food>(`${environment.backend}/api/create.php`, food);
  }
  updateFood(food: Food){
    return this.httpClient.put<Food>(`${environment.backend}/api/update.php`, food);   
  }
  deleteFood(id: number){
    return this.httpClient.delete<Food>(`${environment.backend}/api/delete.php/?id=${id}`);
  }

  public dateFromString(d: string): Date {
    const year = +d.substr(6, 4);
    const month = +d.substr(3, 2);
    const day = +d.substr(0, 2);
    return new Date(year, month - 1, day, 12, 0, 0);
  }

  public dateToString(d: Date): string {
    let day = String(d.getDate());
    let month = String(d.getMonth() + 1);
    if (day.length === 1) {
      day = '0' + day;
    }
    if (month.length === 1) {
      month = '0' + month;
    }

    return day + '.' + month + '.' + d.getFullYear();
  }

  public getFoodCategories(): string[] {
    return [
      'Fisch', 
      'Geflügel', 'Geflügelbrühe', 
      'Gekochte Speisen', 
      'Gemüse',
      'Lamm',
      'Marmelade',
      'Obst',
      'Rind', 'Rinderbrühe', 
      'Schwein',
      'Tiefkühlkost',
      'Wild',
      'Wurst'
    ];
  }

}

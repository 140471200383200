<h2>Aktueller Inhalt</h2>
  
<mat-form-field>
  <input #filterValue matInput (keyup)="applyFilter(filterValue.value)" placeholder="Filter">
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort >

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Id Category -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef > Kategorie </th>
      <td mat-cell *matCellDef="let element"> {{element.category}} </td>
    </ng-container>

    <!-- Label Column -->
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Bezeichnung </th>
      <td mat-cell *matCellDef="let element"> {{element.label}} </td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef > Menge </th>
      <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
    </ng-container>

    <!-- Added Column -->
    <ng-container matColumnDef="added">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hinzugefügt </th>
      <td mat-cell *matCellDef="let element"> {{element.added}} </td>
    </ng-container>

    <!-- Bestbefore Column -->
    <ng-container matColumnDef="bestbefore">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mindesthaltbarkeit </th>
      <td mat-cell *matCellDef="let row; let element" [ngClass]="{'expired': hasExpired(row.bestbefore)}"> {{element.bestbefore}} </td>
    </ng-container>
    
    <!-- Expires Column -->
    <ng-container matColumnDef="expires">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > Haltbar bis </th>
      <td mat-cell *matCellDef="let row; let element" [ngClass]="{'expired': hasExpired(row.expires)}"> {{element.expires}} </td>
    </ng-container>
    
    <!-- Expires Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef > Position </th>
      <td mat-cell *matCellDef="let row; let element" > {{element.position}} </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef > Aktion </th>
      <td mat-cell *matCellDef="let row; let element">
        <a routerLink="/detail/{{element.id}}"><span class="material-icons">visibility</span></a>
        &nbsp;
        <span class="material-icons">delete</span> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10,20,50,100]" showFirstLastButtons></mat-paginator>

</div>

<div *ngIf="initialized && food">
    <h2 *ngIf="food.valid == 1">Detaillierte Info</h2>
    <h2 *ngIf="food.valid != 1">Lebensmittel wurde bereits gel&ouml;scht!</h2>
    <div class="food-details">
      <mat-form-field>
        <input matInput placeholder="ID" disabled [(ngModel)]="food.id">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Postition" [(ngModel)]="food.position">
      </mat-form-field>
      <mat-form-field>
      <mat-select placeholder="Kategorie" [(ngModel)]="food.category">
        <mat-option *ngFor="let cat of foodCategories" value="{{cat}}">{{cat}}</mat-option>
      </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Bezeichnung" [(ngModel)]="food.label">
      </mat-form-field>
      <mat-form-field>
          <input matInput placeholder="Menge" [(ngModel)]="food.quantity">
      </mat-form-field>
      <mat-form-field>
          <input matInput placeholder="Eingelagert" [(ngModel)]="food.added">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Mindesthaltbarkeit" [(ngModel)]="food.bestbefore">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Haltbar bis" [(ngModel)]="food.expires">
      </mat-form-field>
    </div>
    <button *ngIf="food.valid == 1" mat-raised-button (click)="remove()">Entfernen</button>
    <button *ngIf="food.valid != 1" mat-raised-button (click)="undelete()">Wieder einlagern</button>
    &nbsp;
    <button *ngIf="food.valid == 1" mat-raised-button (click)="update()">Aktualisieren</button>
</div>
  
<!-- Food does not exist: allow user to register new food -->
<div *ngIf="initialized && !food">
    <h2>Einlagerung</h2>
    <div class="food-details">
      <mat-form-field>
        <input matInput placeholder="ID" disabled value="{{id}}" #foodId>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Lagerort" #foodPosition>
      </mat-form-field>ISE
      <mat-form-field>
        <mat-select placeholder="Kategorie" #foodCategory>
        <mat-option *ngFor="let cat of foodCategories" value="{{cat}}">{{cat}}</mat-option>
      </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Bezeichnung" #foodLabel>
      </mat-form-field>
      <mat-form-field>
          <input matInput placeholder="Menge" #foodQuantity>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Mindesthaltbarkeit" #foodBestbefore>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Haltbar bis" #foodExpires>
      </mat-form-field>
    </div>
<!--  
    <button mat-raised-button 
            matTooltip="Berechnet die Haltbarkeit basierend auf der gewählten Kategorie"
            (click)="foodBestbefore.value = calculateBestbefore(foodCategory.value);foodExpires.value = calculateExpires(foodCategory.value)">
      Haltbarkeit berechnen
    </button>&nbsp;
  
    <button  mat-raised-button (click)="add(foodCategory.value, foodLabel.value, foodQuantity.value, foodBestbefore.value, foodExpires.value, foodPosition.value)">
      Jetzt einlagern
    </button>
-->
</div>
  
  
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { Food } from '../food';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit {

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 720px)`);
  displayedColumns: string[] = ['id', 'label', 'expires', 'action'];
  displayedColumnsXL: string[] = ['id', 'category', 'label', 'quantity', 'added', 'bestbefore', 'expires', 'action'];
  columnsToDisplay: string[] = this.isScreenSmall() ? this.displayedColumns : this.displayedColumnsXL;
  dataSource = new MatTableDataSource<Food>();

  foodlist: Food[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: ApiService) { 
  }

  ngOnInit(): void {
    this.apiService.readFoods()
      .subscribe(foodlist => {
        this.dataSource.data = foodlist;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'added': {
          return item.added;
        }
        case 'bestbefore': {
          return item.bestbefore;
        }
        case 'expires': {
          return item.expires;
        }
        default: 
          return item.id;
      }
    };

  }

  delete(food: Food): void {
    this.foodlist = this.foodlist.filter(f => f !== food);
    this.apiService.deleteFood(food.id).subscribe();
  }


  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  hasExpired(date: string): boolean {
    const today = new Date();
    const expires = this.apiService.dateFromString(date);
    return today > expires;
  }

  isScreenSmall(): boolean {
    return this.mediaMatcher.matches;
  }

}
export class Food {
    id!: number;
    valid!: number;
    stock!: string;
    position!: string;
    category!: string;
    label!: string;
    quantity!: string;
    added!: string;
    bestbefore!: string;
    expires!: string;
}